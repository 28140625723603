import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta' 

import { lazyLoadView } from "@/utils/lazyload"

import store from '@/state'

import { Message } from 'element-ui';

Vue.use(VueRouter)
Vue.use(VueMeta, {
	// vue-meta在其上查找元信息的组件选项名(在vue文件中查找keyName:对应的属性名作为页面属性)
	keyName: 'page',
})


const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}


const routes = [
	{
		path: '/',
		component: () => lazyLoadView(import('@/views/knowledge.vue'))
	},
	{
		path: '/designer',
		component: () => lazyLoadView(import('@/views/designer.vue'))
	},
	{
		path: '/industry',
		component: () => lazyLoadView(import('@/views/industry.vue'))
	},
	{
		path: '/industryRecruit',
		component: () => lazyLoadView(import('@/views/industryRecruit.vue'))
	},
	{
		path: '/about',
		component: () => lazyLoadView(import('@/views/about.vue'))
	},
	{
		path: '/activity/:id',
		component: () => lazyLoadView(import('@/views/activity.vue'))
	},
	{
		path: '/works',
		component: () => lazyLoadView(import('@/views/works.vue'))
	},
	{
		path: '/offline',
		component: () => lazyLoadView(import('@/views/index.vue'))
	},
	{
		path: '/knowledge/detail/:id',
		component: () => lazyLoadView(import('@/views/knowledgeDetail.vue'))
	},
	{
		path: '/login',
		name: 'login',
		component: () => lazyLoadView(import('@/views/login.vue'))
	},
	{
		path: '/banner/:id',
		component: () => lazyLoadView(import('@/views/banner.vue'))
	},
	{
		path: '/personal',
		component: () => lazyLoadView(import('@/views/personal/index.vue')),
		children: [
			{
				path: 'order',
				component: () => lazyLoadView(import('@/views/personal/order.vue')),
				meta: {
					authRequired: true
				}
			},
			{
				path: 'video',
				component: () => lazyLoadView(import('@/views/personal/video.vue')),
				meta: {
					authRequired: true
				}
			},
			{
				path: 'audio-detail/:id',
				component: () => lazyLoadView(import('@/views/personal/AudioDetail.vue')),
				meta: {
					authRequired: true
				}
			},
			{
				path: 'video-detail/:id',
				component: () => lazyLoadView(import('@/views/personal/VideoDetail.vue')),
				meta: {
					authRequired: true
				}
			},
			{
				path: 'audio',
				component: () => lazyLoadView(import('@/views/personal/audio.vue')),
				meta: {
					authRequired: true
				}
			},
			{
				path: 'document',
				component: () => lazyLoadView(import('@/views/personal/document.vue')),
				meta: {
					authRequired: true
				}
			},
		]
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach((routeTo, routeFrom, next) => {

	// 检查此路由上是否需要验证(即：检验路由中“meta: { authRequired: true }”的值)
	// (包括嵌套路由).
	const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

	// 如果路由不需要验证，则放行
	if (!authRequired) return next()

	if(store.getters["loggedIn"]) {

		store.dispatch("auth/getInfo")
		.then(response => {
			if(response.code !== "-9") {
				return next()
			}
			else {
				if(typeof response.message !== 'undefined' && response.message !== '') {
					//Message.info(response.message)
				}
			}
			redirectToLogin()
		})
		.catch(error => {
			redirectToLogin()
		})
	}
	else {
		redirectToLogin()
	}

	// token无效一律跳转至登录页
	function redirectToLogin() {
		// 将原始路由传递到登录组件
		next({ name: 'login', query: { redirect: routeTo.fullPath } })
	}
})

export default router
