import focus from "./focus"
import home from "./home"
import commend from "./commend"
import aliyun from "./aliyun"
import auth from "./auth"
import settings from "./settings"
import contact from "./contact"
import wechat from "./wewechat"

export default {
	focus,
	home,
	commend,
	aliyun,
	auth,
	settings,
	contact,
	wechat
}