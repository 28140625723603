import request from "@/utils/request"

export default {
	getJsapiSignature(url) {
		return request({
			url: `/api/frontEnd/wechat/mpwechat/getJsapiSignature?url=${url}`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
}