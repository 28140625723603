import request from "@/utils/request"

export default {
	findAll(name, category, pageIndex, pageSize) {
		let queryString = `name=${name}&category=${category}&pageIndex=${pageIndex}&pageSize=${pageSize}`
		return request({
			url: `/api/frontEnd/focus/photoWall/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: false 
				}
			}
		});	
	},
	addDownload(photo) {
		return request({
			url: `/api/frontEnd/focus/photoWall/addDownload`,
			method: "post",
			data: photo,
			options: {
                loading: {
					show: false
				}
			}
		});
	}
}