import request from "@/utils/request"

export default {
	findAll(title, pageIndex, pageSize) {
		let queryString = `title=${title}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/frontEnd/home/snowflake/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
}