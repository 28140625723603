import request from "@/utils/request"

export default {
	sendSms(phoneNumbers) {
		let sms = {
			phoneNumbers: phoneNumbers
		}

		return request({
			url: `/api/frontEnd/aliyun/sms/sendSms`,
			method: "post",
			data: sms,
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
}