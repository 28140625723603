import request from "@/utils/request"

export default {
	findAll(courseName, category, pageIndex, pageSize) {
		let queryString = `courseName=${courseName}&category=${category}&pageIndex=${pageIndex}&pageSize=${pageSize}`
		return request({
			url: `/api/frontEnd/aliyun/knowledge/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: false 
				}
			}
		});	
	},
	getSingle(id) {
		return request({
			url: `/api/frontEnd/aliyun/knowledge/getSingle?id=${id}`,
			method: "get",
			options: {
                loading: {
					show: false 
				}
			}
		});	
	},
	getPlayInfo(id) {
		return request({
			url: `/api/frontEnd/aliyun/knowledge/getPlayInfo?id=${id}`,
			method: "get",
			options: {
                loading: {
					show: false 
				}
			}
		});	
	}
}