import axios from "axios";
import store from '@/state';
import encrypt from "./encrypt";
import moment from 'moment';
import guid from './guid';

import {
	Loading,
	Message
} from "element-ui";

const baseURL = process.env.VUE_APP_BASE_API;
const key = '1a2b3c4d5e'

const request = axios.create({
	baseURL, // 请求地址
	headers: {
		'Content-Type': 'application/json'
	},
	//timeout: 5000 // 超时时间 1000单位为1秒
});

const loading = {
	loadingInstance: null,
	open: function (options) {

		if (typeof options !== "undefined") {
			let loading = options.loading

			if (this.loadingInstance === null && typeof loading !== "undefined") {
				if (loading.show) {
					this.loadingInstance = Loading.service(
						{
							lock: true,
							text: loading.message,
							spinner: "el-icon-loading",
							background: "rgba(0, 0, 0, 0.7)"
						});
				}
			}
		}
		
	},
	// 关闭加载
	close: function () {
		// 不为空时则关闭加载窗口
		if (this.loadingInstance !== null) {
			this.loadingInstance.close();
		}
		this.loadingInstance = null;
	}
};

// 请求拦截器
request.interceptors.request.use(
	config => {
		loading.open(config.options)

		if (store.getters["token"] !== 'undefined') {
			config.headers["auth-token"] = store.getters["token"];
		}

		let timestamp = moment().unix().toString();
		let nonce = guid.newGuid();

		let array = [timestamp, nonce, key]
		let content = array.sort().join(',')

		let signature = encrypt.aesEncrypt(content)

		config.headers["timestamp"] = timestamp;
		config.headers["nonce"] = nonce;
		config.headers["signature"] = signature;

		return config;
	},
	error => {
		loading.close();
		return Promise.reject(error);
	}
);

// 响应拦截器
request.interceptors.response.use(
	response => {
		loading.close();
		return response.data;
	},
	error => {
		loading.close();
		Message.error("网络请求失败,无法连接至远程服务器");
		return Promise.reject(error);
	}
);

export default request;