import request from "@/utils/request"

export default {
	findAll(category) {
		return request({
			url: `/api/frontEnd/setting/dictionary/findAll?category=${category}`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
	findUserAllowVideoCategory() {
		return request({
			url: `/api/frontEnd/setting/dictionary/findUserAllowVideoCategory`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
}