import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './state'

/* begin--导入element-ui组件 */
import './plugins/element-ui'
/* end--导入element-ui组件 */

/* begin--导入wowjs */
import './plugins/wow'
/* end--导入wowjs */

/* begin--导入jquery组件 */
import './plugins/jquery'
/* end--导入jquery组件 */

/* begin--导入样式文件 */
import './style/css/master.css?v=1.01'
import './style/fonts/iconfont.css'
import './style/css/header.css'
import './style/css/footer.css?v=1.01'
/* end--导入样式文件 */

import api from "@/api/index"
Vue.prototype.$api = api

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
