import request from "@/utils/request"

export default {
	findAll(name, category, pageIndex, pageSize) {
		let queryString = `name=${name}&category=${category}&pageIndex=${pageIndex}&pageSize=${pageSize}`
		return request({
			url: `/api/frontEnd/aliyun/audio/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: false 
				}
			}
		});	
	},
	getPlayInfo(id) {
		return request({
			url: `/api/frontEnd/aliyun/audio/getPlayInfo?id=${id}`,
			method: "get",
			options: {
                loading: {
					show: false 
				}
			}
		});	
	}
}