import request from "@/utils/request"

export default {
	findAll(shopName) {
		let queryString = `shopName=${shopName}`

		return request({
			url: `/api/frontEnd/commend/industry/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
}