const TOKEN_KEY = "c-floral-auth-token"
const GRADE_KEY = "c-floral-user-grade"

import { getStorage, setStorage, removeStorage } from "@/utils/storage"
import api from "@/api"

const state = {
	user: {
		token: getStorage(TOKEN_KEY),
		phoneNumbers: '',
		grade: getStorage(GRADE_KEY),
		vipRoles: []
	}
}
const mutations = {
	SET_TOKEN(state, token) {
		state.user.token = token;
		setStorage(TOKEN_KEY, token)
	},
	SET_USER(state, user) {
		setStorage(GRADE_KEY, user.grade)
		state.user.grade = user.grade;
		state.user.phoneNumbers = user.phoneNumbers;
		if(typeof user.vipRoles !== 'undefined' && user.vipRoles !== '') {
			state.user.vipRoles = user.vipRoles.split(',');
		}
	}
}

const actions = {
	login({commit}, sms) 
	{
		return new Promise((resolve, reject) => 
		{
			api.auth.user.login(sms)
			.then(response => {
				if(response.code === "0") {
					// 用户登录成功之后，将服务器返回的token数据缓存到store中	
					commit("SET_TOKEN", response.data)
				}
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},
	getInfo({commit}) {
		return new Promise((resolve, reject) => {
			api.auth.user.getInfo()
			.then(response => {
				if(response.code === '0') {
					commit("SET_USER", response.data)
				}
				else if(response.code === '-9') {
					commit("SET_TOKEN", "");
				}

				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},
	logout({commit}) {
		return new Promise(resolve => {
			commit("SET_TOKEN", "");
			removeStorage(TOKEN_KEY);
			resolve();
		});
	},
}

export {
	state,
	mutations,
	actions
}