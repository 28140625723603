import request from "@/utils/request"

export default {
	findAll() {
		return request({
			url: `/api/frontEnd/home/activity/findAll`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
	getSingle(id) {
		return request({
			url: `/api/frontEnd/home/activity/getSingle?id=${id}`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
	prepay(order) {
		return request({
			url: `/api/frontEnd/home/activity/prepay`,
			method: "post",
			data: order,
			options: {
                loading: {
					show: true,
					message: '正在生成订单，请稍候...'
				}
			}
		});	
	}
}