import camelCase from 'lodash/camelCase'

const modulesCache = {}
const storeData = { modules: {} }

	; (function updateModules() {
		// 允许我们动态地加载所有Vuex模块文件
		const requireModule = require.context(
			// 搜索当前目录中的文件
			'.',
			// 允许搜索子目录中的文件
			true,
			// 除index.js和含有unit字样的js文件外加载所有.js文件
			/^((?!index|\.unit\.).)*\.js$/
		)

		// 遍历每一个Vuex模块...
		requireModule.keys().forEach((fileName) => {
			const moduleDefinition = requireModule(fileName)

			// 如果它引用的与我们缓存的模块定义相同，则在热重新加载时跳过该模块
			if (modulesCache[fileName] === moduleDefinition) return

			// 更新模块缓存，以实现高效的热加载。
			modulesCache[fileName] = moduleDefinition

			// 以数组形式获取模块路径
			const modulePath = fileName
				// 删除以 "./" 开头的内容
				.replace(/^\.\//, '')
				// 从末尾删除文件扩展名
				.replace(/\.\w+$/, '')
				// 将嵌套模块拆分为数组路径
				.split(/\//)
				// camelCase所有模块命名空间和名称
				.map(camelCase)

			// 获取当前路径的模块对象
			const { modules } = getNamespace(storeData, modulePath)

			// 将该模块添加到我们的模块对象中
			modules[modulePath.pop()] = {
				// 模块使用默认的命名空间
				namespaced: true,
				...moduleDefinition,
			}
		})

		// 如果环境支持热重载...
		if (module.hot) {
			// 当任何Vuex模块被更新时...
			module.hot.accept(requireModule.id, () => {
				// 更新 `storeData.modules` 使用最新的定义.
				updateModules()
				// 触发存储中的热更新.
				require('..').default.hotUpdate({ modules: storeData.modules })
			})
		}
	})()

// 递归地获取Vuex模块的命名空间，即使是嵌套的
function getNamespace(subtree, path) {
	if (path.length === 1) return subtree

	const namespace = path.shift()
	subtree.modules[namespace] = {
		modules: {},
		namespaced: true,
		...subtree.modules[namespace],
	}
	return getNamespace(subtree.modules[namespace], path)
}

export default storeData.modules
