import request from "@/utils/request"

export default {
	findAll(category) {
		return request({
			url: `/api/frontEnd/focus/focusPic/findAll?category=${category}`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	},
	getSingle(id) {
		return request({
			url: `/api/frontEnd/focus/focusPic/getSingle?id=${id}`,
			method: "get",
			options: {
                loading: {
					show: false
				}
			}
		});	
	}
}