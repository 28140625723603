export function lazyLoadView(AsyncView) {
	const AsyncHandler = () => ({
		// 需要加载的组件（应该是一个Promise对象）
		component: AsyncView,
		// 异步组件加载时使用的组件
		//loading: require('@components/_loading').default,
		// 展示加载时组件的延时时间，默认是200毫秒
		// Default: 200 (milliseconds).
		delay: 400,
		timeout: 10000,
	})

	return Promise.resolve({
		functional: true,
		render(h, { data, children }) {
			// Transparently pass any props or children
			// to the view component.
			return h(AsyncHandler, data, children)
		},
	})
}