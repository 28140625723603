/**
 * 工具类
 */
import CryptoJS from 'crypto-js'

const aes_key = "1q2wa3es4rz5tx6ycD*UF8tif9ogjen2"

export default {
	//加密
	aesEncrypt(content) {
		let key = CryptoJS.enc.Utf8.parse(aes_key);
		let srcs = CryptoJS.enc.Utf8.parse(content);
		let encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });

		return encrypted.toString();
	},
	//解密
	aesDecrypt(content) {
		var key = CryptoJS.enc.Utf8.parse(aes_key);
		var decrypt = CryptoJS.AES.decrypt(content, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
		
		return CryptoJS.enc.Utf8.stringify(decrypt).toString();
	}

}