import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules,
	getters,
	// 在开发模式下禁用严格模式（言外之意，不允许通过非mutations的方式给store中的state赋值）
	strict: process.env.NODE_ENV !== 'production',
})

export default store