export function getStorage(key) {
	return localStorage.getItem(key);
}

export function setStorage(key, content) {
	return localStorage.setItem(key, content);
}

export function removeStorage(key) {
	return localStorage.removeItem(key);
}
