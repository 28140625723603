import request from "@/utils/request"

export default {
	login(sms) {
		return request({
			url: `/api/frontEnd/auth/user/login`,
			method: "post",
			data: sms,
			options: {
                loading: {
					show: true,
					message: "登录中，请稍候..."
				}
			}
		});
	},
	getInfo() {
		return request({
			url: `/api/frontEnd/auth/user/getInfo`,
			method: "get"
		});
	},
	findAllPrepay(activityName) {
		let queryString = `activityName=${activityName}`

		return request({
			url: `/api/frontEnd/auth/user/findAllPrepay?${queryString}`,
			method: "get"
		});
	}
}